import React from 'react';
import 'bootstrap/dist/css/bootstrap.css';
import './Home.css';

const images = ["https://memsworld.b-cdn.net/NYC/nyc1.png", "https://memsworld.b-cdn.net/NYC/nyc2.png", "https://memsworld.b-cdn.net/NYC/nyc3.png", "https://memsworld.b-cdn.net/NYC/nyc4.png", "https://memsworld.b-cdn.net/NYC/nyc5.png", "https://memsworld.b-cdn.net/NYC/nyc6.png", "https://memsworld.b-cdn.net/NYC/nyc7.png", "https://memsworld.b-cdn.net/NYC/nyc8.png", "https://memsworld.b-cdn.net/NYC/nyc9.png", "https://memsworld.b-cdn.net/NYC/nyc10.png"]
var index = 0


class Event extends React.Component { 

  constructor() {
    super();
    this.state = { 
      clickImg: "https://memsworld.b-cdn.net/NYC/click.png",
      arrowImg: "https://memsworld.b-cdn.net/NYC/arrow.png",
      currentImg: images[index],
      audioPlaying: false,

    };

    
    
  }

  componentDidMount()
  {
    window.location.replace("/#start")
    
  }

  componentDidUpdate( )
  { 

   

  }
    

  
  

  

  
  render()
  {  
    
    const renderer = ({ days, hours, minutes, seconds, completed }) => {
      if (completed) {
        // Render a completed state
        return <h1></h1>;
      } else {
        // Render a countdown
        console.log(hours)
        return <h1 className='oneLine'>
         {days * 24 + hours} hours / {minutes} minutes / {seconds} seconds</h1>;
      }
    };

    

    

    return (
      <div className="pageContainer" id='start'>  
        <div className='full-center'>
         <center>
           <img className='header' src="https://memsworld.b-cdn.net/NYC/header.png"></img>
           <br></br>
           <img src={"https://memsworld.b-cdn.net/NYC/logo.png"} className='logo' style={{margin: 'solid red 3px'}}></img>
           <br></br>
           <div className='specialDiv'>
           <img className='header' src="https://memsworld.b-cdn.net/NYC/subheader.png"></img>
            
           
           </div>
          </center>
        </div>
        <div className='under-center'>
          <br></br>
          <br></br>
          <br></br>
          <center>
          <img className='arrow' src={this.state.arrowImg} onMouseEnter={e => (this.setState({arrowImg: "https://memsworld.b-cdn.net/NYC/arrow_hover.png"}))} onMouseLeave={e => (this.setState({arrowImg: "https://memsworld.b-cdn.net/NYC/arrow.png"}))} onClick={e => {window.location.replace("/#event");this.setState({arrowImg: "https://memsworld.b-cdn.net/NYC/arrow.png"})}}></img>
          </center>
        </div>
        <div className='rest-of-content' id="event">
          <center>
            <br></br>
            <img src="https://memsworld.b-cdn.net/NYC/memslogo.png" className='header'></img>
            <br></br>
            
            <a href="https://memsvr.netlify.com" target="_blank" rel="noopener noreferrer">
              <img src="https://memsworld.b-cdn.net/NYC/atHome.png" className='virtual' style={{marginTop: '1.5em'}}></img>
            </a>
            <br></br>
  
            <a href="https://memsar.netlify.com" target="_blank" rel="noopener noreferrer">
              <img src="https://memsworld.b-cdn.net/NYC/arButton.png" className='ar' style={{marginTop: '1.5em'}}></img>
            </a>
            
            <br></br>
            <img src="https://memsworld.b-cdn.net/NYC/onboarding.png" className='ar' style={{marginTop: '2.5em'}}></img>
            <br></br>
            <form action="/" method='POST' name='outreach' netlify onSubmit={e=> alert("Thank You, we'll get back to you soon!")}>
              <input type="hidden" name="form-name" value="outreach" />
              <input type="text" id="info" name="info" required="required" placeholder="point of contact" className="ar" style={{marginTop: '1.5em', textAlign: 'center'}}/>
              <br></br>
              <button type="submit" value="Submit" id="submit" className='submitBtn' style={{marginTop: '1.5em'}}><img src="https://memsworld.b-cdn.net/NYC/submit.png" style={{width: '100%'}}></img></button>
              
            </form>
            <br></br>
            
            
            <div className='twitterFeed'>
              
              
              
        
            <img src={this.state.currentImg} className='memsDoingThings'></img>
            
            <br></br>
            <img src={this.state.clickImg} className='click' onMouseEnter={e => (this.setState({clickImg: "https://memsworld.b-cdn.net/NYC/click_hover.png"}))} onMouseLeave={e => (this.setState({clickImg: "https://memsworld.b-cdn.net/NYC/click.png"}))} onClick={e => {if(index != 9){index+=1} else {index=0};this.setState({clickImg: "https://memsworld.b-cdn.net/NYC/click.png", currentImg: images[index]})}}
            
            ></img>
            <br></br>
            <br></br>
            <img src={"https://memsworld.b-cdn.net/NYC/footer_logo.png"} className='footerLogo' style={{paddingBottom: '1.5em'}}></img>
            
            </div>
          </center>
        
        </div>

        
      </div>
      )
    
    
          
      
  }
}
export default Event;
